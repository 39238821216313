:root {
  --rmdp-primary-green: #3d9970;
  --rmdp-secondary-green: #87ad92;
  --rmdp-shadow-green: #87ad92;
  --rmdp-today-green: #01ff70;
  --rmdp-hover-green: #2ecc40;
  --rmdp-deselect-green: #39795c;
}

.green .rmdp-wrapper {
  border: 1px solid var(--rmdp-secondary-green);
  box-shadow: 0 0 5px var(--rmdp-secondary-green);
}

.green .rmdp-panel-body li {
  background-color: var(--rmdp-primary-green);
  box-shadow: 0 0 2px var(--rmdp-secondary-green);
}

.green .rmdp-week-day {
  color: var(--rmdp-primary-green);
}

.green .rmdp-day.rmdp-deactive {
  color: var(--rmdp-secondary-green);
}

.green .rmdp-range {
  background-color: var(--rmdp-primary-green);
  box-shadow: 0 0 3px var(--rmdp-shadow-green);
}

.green .rmdp-arrow {
  border: solid var(--rmdp-primary-green);
  border-width: 0 2px 2px 0;
}

.green .rmdp-arrow-container:hover {
  background-color: var(--rmdp-primary-green);
  box-shadow: 0 0 3px var(--rmdp-secondary-green);
}

.green .rmdp-panel-body::-webkit-scrollbar-thumb {
  background: var(--rmdp-primary-green);
}

.green .rmdp-day.rmdp-today span {
  background-color: var(--rmdp-today-green);
}

.green .rmdp-rtl .rmpd-panel {
  border-left: unset;
  border-right: 1px solid var(--rmdp-secondary-green);
}

.green .rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: var(--rmdp-primary-green);
  box-shadow: 0 0 3px var(--rmdp-shadow-green);
}

.green .rmdp-day:not(.rmdp-day-hidden) span:hover {
  background-color: var(--rmdp-hover-green) !important;
}

.green .b-deselect {
  color: var(--rmdp-deselect-green);
  background-color: white;
}

.green .rmdp-action-button {
  color: var(--rmdp-primary-green);
}
